export const diccionario_cat={
    hola:'Hola',
    empleados:'Empleats',
    empleado:'Empleat',
    organizacion:'Organització',
    estructura:'Estructura',
    organizativa:'organitzativa',
    crear:'Crear',
    visualizar:'Visualitzar',
    modificar:'Modificar',
    listados:'Llistats',
    listado:'Llistat',
    recursos:'Recursos Humans',
    resumen:"Resum",
    evaluados:"Avaluats",
    evaluado:'Avaluat',
    evaluacion:'Avaluació',
    empezar:'començar',
    empezada:'començada',
    evaluaciones:"Avaluacions",
    evaluada:'Avaluada',
    pruebas:'Proves',
    areas:'Àrees',
    evainiciadas:'Ava. iniciades',
    evarealizadas:'Ava. realitzades',
    ranking:'Ranking empleats per avaluació',
    nombre:'Nom',
    apellidos:'Cognoms',
    area:'Àrea',
    estado:'Estat',
    fecha:'Data',
    resultado:'Resultat',
    todas:'Totes',
    año:'any',
    competencias:'competències',
    destacadas:'destacades',
    buenas:'bones',
    normales:'normals',
    alejadas:'allunyades',
    submitida:'submesa',
    submitidas:'submeses',
    submitir:'submetre',
    completas:'completes',
    periodos:'Períodes',
    fomulario:'Formulari',
    actualizar:'Actualitzar',
    busqueda:"Camps de búsqueda",
    rellenar:"Omple un camp amb el valor de l'empleat que vols recuperar",
    rellenar2:"Omple un camp amb el valor de l'estructura que vols recuperar",
    limpiarcampos:'Buidar camps',
    seleccionado:'seleccionat',
    hombre:'Home',
    mujer:'Dona',
    otros:'Altres',
    genero:'Gènere',
    cargo:'Càrrec',
    acceso:'Accés',
    herramienta:'eina',
    registro:'registre',
    telefono:'Telèfon',
    inicio:'inici',
    Bueno:'Bo',
    Destacado:'Destacat',
    Alejado:'Allunyat',
    Normal:'Normal',
    sin:'Sense',
    finalizar:'finalitzar',
    aviso0:"Has de respondre almenys alguna pregunta del qüestionari per començar l'avaluació",
    aviso1:"Per poder finalitzar l'avaluació has de respondre totes les qüestions",
    aviso2:"Per submetre l'avaluació has d'activar el botó 'Submetre'",
    aviso3:"AVALUACIÓ SOMESA, qualsevol modificació posa't en contacte amb l'administrador",
    gerente:'Gerent',
    observaciones:'Observacions',
    aviso4:"¿Vols Voleu reobrir l'avaluació?",
    aviso5:"Vols submetre l'avaluació? Un cop submesa ja no la podràs modificar",
    DELAORGANIZACION:'DE LA ORGANITZACIÓ (CORE)',
    PERSONALES:'PERSONALS',
    'DEL PUESTO':'DEL LLOC',
    '(FUNCIONALES)':'(FUNCIONALS)',
    INTERPERSONALES:'INTERPERSONALS',
    DELPUESTOFUNCIONALES:'DEL LLOC (FUNCIONALS)',
    SeguridadSocial:"Seguritat Social",
    titulacion:'Titulació',
    antiguedad:'Antigüetat',
    puestotrabajo:'Lloc de treball',
    puesto:'Lloc',
    puestostrabajo:'Llocs de treball',
    situacion:'Situació',
    grupo:'Grup',
    descripcion:'Descripció', 
    codigo:'Codi',
    nivel:'Nivell',
    delas:'de les',
    funciones:'funcions',
   para:'per',
   tipo:'Tipus',
   estecampoobligatorio:'Aquest és un camp obligatori',
   'Titulación de Licenciatura en Arquitectura, Ingeniería o equivalente':'Titulació de Llicenciatura en Arquitectura, Enginyeria o equivalent',
   'Titulación de Diplomatura, Ingeniería técnica, Arquitectura o equivalente':'Titulació de Diplomatura, Enginyeria tècnica, Arquitectura o equivalent',
   'Los opositores deben tener el título de Técnico Superior en FP o Formación de Grado Superior':'Els opositors han de tenir el títol de tècnic superior en FP o formació de grau superior',
   'La titulación mínima exigida es el Bachillerato, Formación Profesional de Grado Medio o Técnico en FP':'La titulació mínima exigida és el Batxillerat, Formació Professional de Grau Mitjà o Tècnic en FP',
'Título en graduado en ESO, el Graduado escolar, el FP1 o equivalentes':"Títol en graduat a l'ESO, el Graduat escolar, l'FP1 o equivalents",

sinempleadoasignado:'Sense empleat asignat',
provision:'Provisió',
'Libre Disposición':'Lliure Disposició',
concurso:'Concurs',
sab:'Sou Anual Brut',
complemento:'Complement',
creadocorrectamente:'creat correctament',
creadacorrectamente:'creada correctament',
errorcarga:"Hi ha hagut un error a la càrrega i no s'ha pogut crear",
sinasignar:'SENSE ASSIGNAR',
contraseña:'Has oblidat la contrasenya?',
diseñado:'Disenyat i desarrollat per',

contraseña2:'Contrasenya',
'Vacante':'Vacant',
'Vacante Interina':'Vacant Interina',
'Permanente':'Permanent',
rpt:'RLT',
"Funcionario":"Funcionari",
'FHCN':'FHCN',
'Personal Laboral':'Personal Laboral',
'Personal Eventual':'Personal Eventual',
Hombre:'Home',
Mujer:'Dona',
Otros:"Altres",
Recuérdame:"Recorda'm",
relacion:'Relació',
por:'per',
denominacion:'Denominació',
historico:'Històric',
cambios:'canvis',

usuarioyfecha: 'Usuari i data de modificació',
campo:'Camp',
modifcado:'modificat',
antiguo:'Antic',
nuevo:'Nou'
}