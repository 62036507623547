import React, { createContext, useEffect, useReducer,useState } from 'react'
import jwtDecode from 'jwt-decode'
import axios from 'axios.js'
import { MatxLoading } from 'app/components'
import { signInWithEmailAndPassword,createUserWithEmailAndPassword, signOut, getAuth,updateProfile,onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, onValue} from "firebase/database";
import { getNavigationByUser } from "../redux/actions/NavigationAction"
import { SAMLAuthProvider,signInWithPopup,getRedirectResult ,OAuthProvider } from "firebase/auth";
import {  useNavigate } from "react-router-dom";
import { useStore } from 'react-redux';
import useAuth from 'app/hooks/useAuth';
const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
    basedatos:''
}

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false
    }

    const decodedToken = jwtDecode(accessToken)
    const currentTime = Date.now() / 1000
    return decodedToken.exp > currentTime
}

const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem('accessToken')
        delete axios.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => { },
    register: () => Promise.resolve(),
})

 

const buscaDatosUsuario = async (user) => {
   
return new Promise((resolve) =>   {
    if (user) {
        const db = getDatabase();
        const starCountRef = ref(db, 'Empleados');
     var p22 = new Promise((resolve) =>     onValue(starCountRef, (snapshot) => {
        
        const data = snapshot.val();
        if(data){
          var empleadosList = Object.keys(data).map(key => ({
            ...data[key],
            clave: key,
          }));
          
          const usuario=empleadosList.find((usu)=>!usu.Borrado&&usu.Key===user.uid)
          user['role']='GUEST'
          user['name']='SIN NOMBRE'
    
        if(usuario){
        user['role']=usuario.Perfil
        user['name']=usuario.Nombre+' '+ usuario.Apellido
        user['id']=usuario.id
  
       }else{
        user=null
       }
        
    }

        resolve(user)
    }, {
                onlyOnce: true
              
            }))
    var p1 = new Promise((resolve) =>  
       onValue( ref(db, 'Estructura'), (snapshot) => {
        
    const data = snapshot.val();
    if(data){
      var estructuras = Object.keys(data).map(key => ({
        ...data[key],
        clave: key,
      }));
      estructuras=estructuras.filter((estru)=>!estru.Borrado)
      resolve(estructuras)}
    else{resolve([])} 
    },{
            onlyOnce: true
        }))

    Promise.all([p1,p22]).then(values => {
        //Comprobamos si es gerente y si lo es le asignamos la estrcurturas que puede ver
            if(values[0].length>0&&values[1]){
                values[1]['Gerente']=true
             
                const resultado= values[0].filter(estru=>{
                    
                    if(estru.gerentes&&estru.gerentes.length>0){
                        const gerentes=estru.gerentes
                        const esta=gerentes.find(ger=>ger.id===values[1].id)
                        if(esta){return true}
                    }
                    return false
                })

                values[1]['Estructuras']=resultado
            }
                resolve(values[1])
            
        })
      // ...
    } 
    else {

           resolve(null)
      // User is signed out
      // ...
    }
  
})


}


const estadoAutorizacion = async () => {
    const auth =getAuth()
 

   
return new Promise((resolve) =>    onAuthStateChanged(auth, (user) => {
    if (user) {
    buscaDatosUsuario(user).then((usuario)=>{
        resolve(usuario)
    })
    } else {


    
       resolve(null)
   //resolve(true)
     
      // User is signed out
      // ...
    }
  })
)


}
export const AuthProvider = ({ children }) => {

    const auth =getAuth()
    const [state, dispatch] = useReducer(reducer, initialState)
    const store=useStore()
  
 
    const login = async (email, password,basedatos) => {
     
        const response = await   signInWithEmailAndPassword(auth, email, password)
        
        var {user } = response


     
        if(user){
         const usuario= await buscaDatosUsuario(user)
        
         if(usuario){
     //     user['role']=usuario.Perfil
       //   user['name']=usuario.Nombre+' '+ usuario.Apellido
            user={...usuario}
//          actualizaUsuario(usuario)
       
          const lengu =store.getState().lenguajes
          const diccionario=lengu.diccionario
          const nombreBase =lengu.nombreBase
          getNavigationByUser(store.dispatch,user,diccionario,nombreBase)
        
          const accessToken = user['accessToken']
      
         
          setSession(accessToken)
          dispatch({
            type: 'LOGIN',
            payload: {
                user,basedatos
            },
    })

        }else{ 
            
            
            dispatch({
            type: 'INIT',
            payload: {
                isAuthenticated: false,
                user: null,
                basedatos:null
            },
        
           
            })
            alert('Ponte en contacto con el administrdor de la herramienta, no tiene acceso a esta parte de la plataforma')
        }
   
        }else{
            dispatch({
                type: 'INIT',
                payload: {
                    isAuthenticated: false,
                    user: null,
                    basedatos:null
                },
            })
          
        }
            
      

        
         
    }

    const register = async (email, username, password) => {
        const response = await createUserWithEmailAndPassword(auth, email, password)

        const {user } = response
        const accessToken = user['accessToken']
   
        setSession(accessToken)

        updateProfile(auth.currentUser, {
            displayName: "Pedro García", photoURL: "https://example.com/jane-q-user/profile.jpg"
          }).then(() => {
            // Profile updated!
            // ...
          }).catch((error) => {
            // An error occurred
            // ...
          });

        dispatch({
            type: 'REGISTER',
            payload: {
                user,
            },
        })
    }

    const logout = async () => {
        await signOut(auth)
        setSession(null)
        dispatch({ type: 'LOGOUT' })
    }


    async function  entra (accessToken){
    setSession(accessToken)
    //  const response = await axios.get('/api/auth/profile')
      const  user  = await estadoAutorizacion()
      if(!user){

        await signOut(auth)
        setSession(null)
        dispatch({ type: 'LOGOUT' })
      }else{
//      user['role']='SA'
  //    user['name']=user.displayName
          const lengu =store.getState().lenguajes
          const diccionario=lengu.diccionario
         const nombreBase =lengu.nombreBase


      getNavigationByUser(store.dispatch,user,diccionario,nombreBase)

   return(   dispatch({
          type: 'INIT',
          payload: {
              isAuthenticated: true,
              user,
          },
      }))

      }

}


    useEffect(() => {
        ; (async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken')

                if (accessToken && isValidToken(accessToken)) {
                            entra(accessToken)
                } else {


             //       const provider = new OAuthProvider('microsoft.com');
//                    const provider = new SAMLAuthProvider('saml.prueba-sal');
      
      //              const auth = getAuth();
        //            provider.setCustomParameters({
                        // Force re-consent.
                   //     prompt: 'consent',
                        // Target specific email with login hint.
                      //  client_id:'e1af9b25-1c73-44ff-84dc-0f2e8e0ae5b7',
                      //  login_hint: 'rondon.grijalba.pablo@gmail.com'
          //            });
            //         const res1= await signInWithPopup(auth, provider)
              //      if(res1){


                  //     entra(res1.user.accessToken)}
                //    else{
                        dispatch({
                            type: 'INIT',
                            payload: {

                                isAuthenticated: false,
                                user: null,
                                basedatos:null
                            },
                        })
    
   //                 }
                }
            } catch (err) {
                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                        basedatos:null
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                register,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
