export const diccionario_es={
    hola:'Hola',
    empleados:'Empleados',
    empleado:'Empleado',
    empezar:'empezar',
    empezada:'empezada',
    
    organizacion:'Organización',
    estructura:'Estructura',
    organizativa:'organizativa',
    crear:'Crear',
    visualizar:'Visualizar',
    modificar:'Modificar',
    listados:'Listados',
    listado:'Listado',
    recursos:'Recursos Humanos',
    resumen:"Resumen",
    evaluados:"Evaluados",
    evaluado:'Evaluado',
    evaluaciones:"Evaluaciones",
    evaluacion:'Evaluación',
    evaluada:'Evaluada',
    pruebas:'Pruebas',
    areas:'Áreas',
    evainiciadas:'Eva. iniciadas',
    evarealizadas:'Eva. realizadas',
    ranking:'Ranking empleados por evaluación',
    nombre:'Nombre',
    apellidos:'Apellidos',
    area:'Área',
    estado:'Estado',
    fecha:'Fecha',
    resultado:'Resultado',
    todas:'Todas',
    año:'año',
    competencias:'competencias',
    destacadas:'destacadas',
    buenas:'buenas',
    normales:'normales',
    alejadas:'alejadas',
    submitida:'submitida',
    submitidas:'submitidas',
    submitir:'submitir',
    completas:'completas',
    periodos:'Periodos',
    fomulario:'Formulario',
    actualizar:'Actualizar',
    busqueda:"Campos de búsqueda",
    rellenar:'Rellena un campo con el valor del empleado que deseas recuperar',
    rellenar2:'Rellena un campo con el valor dela estructura que deseas recuperar',
    limpiarcampos:'Limpiar campos',
    seleccionado:'seleccionado',
  
    genero:'Género',
    cargo:'Cargo',
    acceso:'Acceso',
    herramienta:'herramienta',
    registro:'registro',
    telefono:'Teléfono',
    inicio:'inicio',
    Bueno:'Bueno',
    Destacado:'Destacado',
    Alejado:'Alejado',
    Normal:'Normal',
    sin:'Sin',
    finalizar:'finalizar',
    aviso0:'Debes al menos responder alguna pregunta del cuestionario para empezar la evaluación',
    aviso1:'Para poder finalizar la evaluación debes de responder todas las cuestiones',
    aviso2:"Para submitir la evaluación debes activar el botón 'Submitir'" ,
    aviso3:'EVALUACÍON SUBMITIDA, cualquier modificación ponte en contacto con el administrador',
    gerente:'Gerente',
    observaciones:'Observaciones',
    aviso4:'¿Quieres reabrir la evaluación?',
    aviso5:'¿Quieres submitir la evaluación?, Una vez submitida ya no la podrás modificar',
    DELAORGANIZACION:'DE LA ORGANIZACIÓN (CORE)',
    PERSONALES:'PERSONALES',
    'DEL PUESTO':'DEL PUESTO',
    '(FUNCIONALES)':'(FUNCIONALES)',
    INTERPERSONALES:'INTERPERSONALES',
    DELPUESTOFUNCIONALES:"DEL PUESTO (FUNCIONALES)",
    SeguridadSocial:'Seguridad Social',
    titulacion:'Titulación',
    antiguedad:'Antigüedad',
    puestotrabajo:'Puesto de trabajo',
    puesto:'Puesto',
    puestostrabajo:'Puestos de trabajo',
    situacion:'Situación',
    hombre:'Hombre',
    mujer:'Mujer',
    otros:'Otros',
    grupo:'Grupo',
    descripcion:'Descripción',
    codigo:'Código',
    nivel:'Nivel',
    delas:'de las',
    funciones:'funciones',
    para:'para',
    tipo:'Tipo',
    estecampoobligatorio:'Este es un campo obligatorio',
    'Titulación de Licenciatura en Arquitectura, Ingeniería o equivalente':'Titulación de Licenciatura en Arquitectura, Ingeniería o equivalente',
    'Titulación de Diplomatura, Ingeniería técnica, Arquitectura o equivalente':'Titulación de Diplomatura, Ingeniería técnica, Arquitectura o equivalente',
    'Los opositores deben tener el título de Técnico Superior en FP o Formación de Grado Superior':'Los opositores deben tener el título de Técnico Superior en FP o Formación de Grado Superior',
    'La titulación mínima exigida es el Bachillerato, Formación Profesional de Grado Medio o Técnico en FP':'La titulación mínima exigida es el Bachillerato, Formación Profesional de Grado Medio o Técnico en FP',
 'Título en graduado en ESO, el Graduado escolar, el FP1 o equivalentes':'Título en graduado en ESO, el Graduado escolar, el FP1 o equivalentes',
 sinempleadoasignado:'Sin empleado asignado',
 provision:'Provisión',
 'Libre Disposición':'Libre Disposición',
 concurso:'Concurso',
 sab:'Sueldo Anual Bruto',
 complemento:'Complemento',
creadocorrectamente:'creado correctamente',
creadacorrectamente:'creada correctament',
errorcarga:'Ha habido un error en la carga y no se ha podido crear',
sinasignar:'SIN ASIGNAR',
contraseña:'¿Has olvidado la contraseña?',
diseñado:'Diseñado y desarrollado por',
contraseña2:'Contraseña',
'Vacante':'Vacante',
'Vacante Interina':'Vacante Interina',
'Permanente':'Permanente',
rpt:'RPT',
"Funcionario":"Funcionario",
'FHCN':'FHCN',
'Personal Laboral':'Personal Laboral',
'Personal Eventual':'Personal Eventual',
Hombre:'Hombre',
Mujer:'Mujer',
Otros:"Otros",
Recuérdame:"Recuérdame",
relacion:'Relación',
por:'por',
denominacion:'Denominación',

historico:'Histórico',
cambios:'cambios',
usuarioyfecha: 'Usuario y fecha de modificación',
campo:'Campo',
modifcado:'modificado',
antiguo:'Antiguo',
nuevo:'Nuevo'



}