import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import {  Route, Routes } from 'react-router-dom';
import { authRoles } from '../../auth/authRoles';

const ComunidadesTabla = Loadable(lazy(() => import('./ComunidadesTabla')));
const ComunidadFormModificar = Loadable(lazy(() => import('./ComunidadFormModificar')));


const comunidadRoutes = [

  { path:'/listacomunidades',  element: <ComunidadesTabla/>},
  { path:'/comunidad',  element:<ComunidadFormModificar/> },


]

export default comunidadRoutes;
