import { combineReducers } from 'redux';
import EcommerceReducer from './EcommerceReducer';
import NavigationReducerSlice from './NavigationReducer';
import NotificationReducer from './NotificationReducer';
import LenguajeReducerSlice from './LenguajeReducer';

const RootReducer = combineReducers({
  notifications: NotificationReducer,
  navigations: NavigationReducerSlice,
  ecommerce: EcommerceReducer,
  lenguajes:LenguajeReducerSlice
});

export default RootReducer;
