import { navigations2 } from 'app/navigations';

export const SET_USER_NAVIGATION = 'SET_USER_NAVIGATION';

const getfilteredNavigations = (navList = [], role) => {
  return navList.reduce((array, nav) => {
    if (nav.auth) {
      //Incluimos el control de opciones de navegacon según la base datos que estes

      if (nav.auth.includes(role)) {
        array.push(nav);
      }
    } else {
      if (nav.children) {
        nav.children = getfilteredNavigations(nav.children, role);
        array.push(nav);
      } else {
        array.push(nav);
      }
    }
    return array;
  }, []);
};

export function getNavigationByUser(dispatch,user,diccionario,baseactual) {
    const navigations=navigations2(diccionario)
    let filteredNavigations = getfilteredNavigations(navigations, user.role);

if(user.role!=='SA'){
    //Aplicamos filtro de bases de datos solo si no erea SUPER ADMIN
    filteredNavigations=navigationFilterbases(filteredNavigations,baseactual)

}
  
  
    dispatch({
      type: SET_USER_NAVIGATION,
      payload: [...filteredNavigations],
    });
    return true
  
}



 //Incluimos el control de opciones de navegacon según la base datos que estes
const navigationFilterbases = (navList = [],baseactual) => {
  return navList.reduce((array, nav) => {

      if (!nav.bases || nav.bases.includes('Todas')||nav.bases.includes(baseactual)) {
      //  array.push(nav);
        if (nav.children) {
          nav.children = navigationFilterbases(nav.children, baseactual);
          array.push(nav);
        } else {
          array.push(nav);
        }
      }
     else {
      
    }
    return array;
  }, []);
};