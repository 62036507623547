import React from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../index';
import { useState } from "react";
import { getAuth} from "firebase/auth";


const withAuthentication = Component => {
  
const WithAuthentication = () => {
  const [authUser, setauthUser] = useState(null);
  const auth =getAuth()

  auth.onAuthStateChanged(
        authUser => {
          authUser
            ? setauthUser(authUser)
            : setauthUser(null);
        },
      );
    

      return (
        <AuthUserContext.Provider value={authUser}>
          <Component  />
        </AuthUserContext.Provider>
      );
    }

  return withFirebase(WithAuthentication);
}

export default withAuthentication;