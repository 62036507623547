import React, { createContext, useState } from 'react'

import { merge } from 'lodash'

import { MatxLayoutSettings } from 'app/components/MatxLayout/settings'
import {cambiarBase} from '../redux/reducers/LenguajeReducer'
import {useDispatch } from 'react-redux';

const SettingsContext = createContext({
    settings: MatxLayoutSettings,
    updateSettings: () => {},
})

export const SettingsProvider = ({ settings, children }) => {

    
const hostname=window.location
const url=hostname.host
let basedatos=url.split('.')[0]
const dispatch = useDispatch()
if('competencias'==basedatos){basedatos='sideco'}
else if(basedatos=="localhost:3000"){basedatos='sideco'}

dispatch(cambiarBase(basedatos))
    const [currentSettings, setCurrentSettings] = useState(
        settings || MatxLayoutSettings
    )

    const handleUpdateSettings = (update = {}) => {
        const marged = merge({}, currentSettings, update)
        setCurrentSettings(marged)
    }

    return (
        <SettingsContext.Provider
            value={{
                settings: currentSettings,
                updateSettings: handleUpdateSettings,
            }}
        >
            {children}
        </SettingsContext.Provider>
    )
}

export default SettingsContext
