import { Card } from  '@mui/material';
import { Box, styled } from '@mui/system';

const CardRoot = styled(Card)((colores) => ({
  height: '100%',
  padding: '0px 24px',
  marginBottom:'45px',
  background: colores.colores
}));



const SimpleCard_sin_titulo = ({ children,  icon, colores, elevacion }) => {
  return (
    <CardRoot elevation={(elevacion ? elevacion:6)} colores={colores}  >
    
      {children}
    </CardRoot>
  );
};

export default SimpleCard_sin_titulo;
