import {diccionario_cat} from './lenguaje_cat'
import {diccionario_es} from './lenguaje_es'

export const lenguaje={


    ESP :{
        diccionario:diccionario_es,
        lenguaje:'ESP'

    },
    CAT :{
        diccionario:diccionario_cat,
        lenguaje:'CAT'

    }
}