
import * as ROUTES from './constants/routes';
import { authRoles } from './auth/authRoles';
import {lenguaje} from '../lenguajes/index'
const initialState =
{
    diccionario:lenguaje.ESP.diccionario,
    lenguaje:lenguaje.ESP.lenguaje

};

const diccionario=initialState.diccionario
export const navigations =[
  { name: 'Dashboard', path: '/dashboard/default', icon: 'dashboard'},

  { label: diccionario.organizacion, type: 'label',auth: authRoles.sa },
  


  { name: diccionario.puestotrabajo, icon: 'group' ,  children: [
    { name:diccionario.crear, iconText: 'SI',auth: authRoles.sa , path: ROUTES.PUESTO },
   // { name: diccionario.modificar, iconText: 'SI',auth: authRoles.sa, path: ROUTES.PUESTO_MODIFICAR },
   // { name: diccionario.visualizar, iconText: 'SI', path: ROUTES.PUESTO_MODIFICAR },
  
    { name: diccionario.listados, iconText: 'SU', path:ROUTES.PUESTOS },
    
  ]},

    { name: diccionario.empleados, icon: 'group' ,  children: [
        { name:diccionario.crear, iconText: 'SI',auth: authRoles.sa , path: ROUTES.EMPLEADO },
        { name: diccionario.modificar, iconText: 'SI',auth: authRoles.sa, path: ROUTES.EMPLEADO_MODIFICAR },
        { name: diccionario.visualizar, iconText: 'SI', path: ROUTES.EMPLEADO_MODIFICAR },
      
        { name: diccionario.listados, iconText: 'SU', path:ROUTES.EMPLEADOS },
        
      ]},
      { name: diccionario.estructura,icon: 'store',   children: [
        { name: 'Resumen', iconText: 'SI',auth: authRoles.sa, path: ROUTES.INFO_ESTRUCTURA },
        { name: diccionario.crear, iconText: 'SI',auth: authRoles.sa, path: ROUTES.ESTRUCTURA },
        { name:diccionario.modificar, iconText: 'SI',auth: authRoles.sa, path:ROUTES.ESTRUCTURA_MODIFICAR },
        { name:diccionario.listados, iconText: 'SU', path: ROUTES.ESTRUCTURAS },
        
      ]
       
  },
  { label:diccionario.recursos, type: 'label',auth: authRoles.sa },
  

      { name: 'Pruebas',auth: authRoles.sa, icon: 'schedule', children: [
        { name: diccionario.crear, iconText: 'SI', path: ROUTES.PRUEBA },
        { name: diccionario.modificar, iconText: 'SI', path: ROUTES.PRUEBA_MODIFICAR },
      
        { name: diccionario.listados, iconText: 'SU', path:ROUTES.PRUEBAS },
        { name:diccionario.listados, iconText: 'SU', path:ROUTES.PRUEBAS },
        
      ]},
      { name: 'Evaluaciones',icon: 'thumb_up',children: [//badge: { value: '30+', color: 'red' },   children: [
        { name:diccionario.crear,auth: authRoles.sa, iconText: 'SI', path: ROUTES.EVALUACION },
        { name: diccionario.modificar,auth: authRoles.sa, iconText: 'SI', path:ROUTES.EVALUACION_MODIFICAR },
        { name: diccionario.listados,auth: authRoles.sa, iconText: 'SU', path: ROUTES.EVALUACIONES },
        { name: 'Evaluados', iconText: 'SU', path: ROUTES.EVALUADOS },
        
      ]
       
  },
  { label: 'LMS', type: 'label',auth: authRoles.sa },
  

      { name: 'Lms', icon: 'schedule',auth: authRoles.sa , children: [
        { name: 'Inicio', iconText: 'SI', path: ROUTES.LMS_HOME },
        { name: 'Curso', iconText: 'SI', path: ROUTES.LMS_CURSO },
      
        { name: 'Curso detalle', iconText: 'SU', path:ROUTES.LMS_CURSO_DETALLE },
        
      ]},
      { name: 'Evaluaciones',icon: 'thumb_up',auth: authRoles.sa ,badge: { value: '30+', color: 'red' },   children: [
        { name:diccionario.crear, iconText: 'SI', path: ROUTES.ESTRUCTURA },
        { name: diccionario.modificar,iconText: 'SI', path:ROUTES.ESTRUCTURA_MODIFICAR },
        { name: diccionario.listados, iconText: 'SU', path: ROUTES.SIGN_UP },
        
      ]
       
  },
  { name: 'Informes',icon: 'trending_up',auth: authRoles.sa ,   children: [
    { name: 'Resumen', iconText: 'SI',auth: authRoles.sa , path: ROUTES.INFORMES },
    
    
  ]
   
},
 
  { label: 'PAGES', type: 'label',auth: authRoles.sa  },
  {
    name: 'Session/Auth',
    icon: 'security',auth: authRoles.sa ,
    children: [
      { name: 'Sign in', iconText: 'SI', path:ROUTES.SIGN_IN },
      { name: 'Sign up', iconText: 'SU', path: ROUTES.SIGN_UP },
       { name: 'Forgot Password', iconText: 'FP', path: '/session/forgot-password' },
      { name: 'Error', iconText: '404', path: '/session/404' },
    ],
  },
  { label: 'Components', type: 'label',auth: authRoles.sa },
  {
    name: 'Components',
    icon: 'favorite',auth: authRoles.sa,
    badge: { value: '30+', color: 'secondary' },
    children: [
      { name: 'Auto Complete', path: '/material/autocomplete', iconText: 'A' },
      { name: 'Buttons', path: '/material/buttons', iconText: 'B' },
      { name: 'Checkbox', path: '/material/checkbox', iconText: 'C' },
      { name: 'Dialog', path: '/material/dialog', iconText: 'D' },
      { name: 'Expansion Panel', path: '/material/expansion-panel', iconText: 'E' },
      { name: 'Form', path: '/material/form', iconText: 'F' },
      { name: 'Icons', path: '/material/icons', iconText: 'I' },
      { name: 'Menu', path: '/material/menu', iconText: 'M' },
      { name: 'Progress', path: '/material/progress', iconText: 'P' },
      { name: 'Radio', path: '/material/radio', iconText: 'R' },
      { name: 'Switch', path: '/material/switch', iconText: 'S' },
      { name: 'Slider', path: '/material/slider', iconText: 'S' },
      { name: 'Snackbar', path: '/material/snackbar', iconText: 'S' },
      { name: 'Table', path: '/material/table', iconText: 'T' },
    ],
  },
  {
    name: 'Charts',
    icon: 'trending_up',auth: authRoles.sa ,
    children: [{ name: 'Echarts', path: '/charts/echarts', iconText: 'E' }],
  },
  {
    name: 'Documentation',
    icon: 'launch',
    type: 'extLink',
    path: 'http://demos.ui-lib.com/matx-react-doc/',
  },


]

export function navigations2(diccionario) {return(
  [
  { name: 'Dashboard', path: '/dashboard/default', icon: 'dashboard',bases:['Todas']},

  { label: diccionario.organizacion, type: 'label',bases:['Todas'] },
  { name: diccionario.estructura,icon: 'store', bases:['Todas'],  children: [
   // { name: diccionario.resumen, iconText: 'SI',auth: authRoles.sa, path: ROUTES.INFO_ESTRUCTURA },
    { name: diccionario.crear, iconText: 'SI',auth: authRoles.admin, path: ROUTES.ESTRUCTURA,bases:['larapita']},
   // { name:diccionario.modificar, iconText: 'SI',auth: authRoles.sa, path:ROUTES.ESTRUCTURA_MODIFICAR },
    { name:diccionario.listados, iconText: 'SU', path: ROUTES.ESTRUCTURAS,bases:['Todas'] },
    
  ]
   
},
{ name: diccionario.puestostrabajo, icon: 'group' ,bases:['larapita'],  children: [
  { name:diccionario.crear, iconText: 'SI',auth: authRoles.admin , path: ROUTES.PUESTO,bases:['larapita'] },
  //{ name: diccionario.modificar, iconText: 'SI',auth: authRoles.sa, path: ROUTES.PUESTO_MODIFICAR },
  //{ name: diccionario.visualizar, iconText: 'SI', path: ROUTES.PUESTO_MODIFICAR },

  { name: diccionario.relacion+' '+diccionario.puestostrabajo+' '+'('+diccionario.rpt+')', iconText: 'SU', path:ROUTES.PUESTOS ,bases:['larapita']}
  
]},

      { name: diccionario.empleados, icon: 'group' , bases:['Todas'], children: [
        { name:diccionario.crear, iconText: 'SI',auth: authRoles.admin , path: ROUTES.EMPLEADO,bases:['larapita'] },
      //  { name: diccionario.modificar, iconText: 'SI',auth: authRoles.sa, path: ROUTES.EMPLEADO_MODIFICAR },
       // { name: diccionario.visualizar, iconText: 'SI', path: ROUTES.EMPLEADO_MODIFICAR },
      
        { name: diccionario.listados, iconText: 'SU', path:ROUTES.EMPLEADOS,bases:['Todas'] },
        
      ]},
  
  { label:diccionario.recursos, type: 'label',auth: authRoles.sa,bases:['sideco'] },
  

      { name: diccionario.pruebas,auth: authRoles.sa, icon: 'schedule',bases:['sideco'], children: [
        { name: diccionario.crear, iconText: 'SI', path: ROUTES.PRUEBA,bases:['larapita'] },
        { name: diccionario.modificar, iconText: 'SI', path: ROUTES.PRUEBA_MODIFICAR,bases:['larapita'] },
      
        { name: diccionario.listados, iconText: 'SU', path:ROUTES.PRUEBAS,bases:['sideco'] },
     
        
      ]},
      { name:diccionario.evaluaciones ,icon: 'thumb_up',bases:['sideco'],children: [//badge: { value: '30+', color: 'red' },   children: [
        { name:diccionario.crear,auth: authRoles.sa, iconText: 'SI', path: ROUTES.EVALUACION ,bases:['sideco']},
        { name: diccionario.modificar,auth: authRoles.sa, iconText: 'SI', path:ROUTES.EVALUACION_MODIFICAR ,bases:['sideco']},
        { name: diccionario.listados,auth: authRoles.sa, iconText: 'SU', path: ROUTES.EVALUACIONES ,bases:['sideco']},
        { name: diccionario.evaluados, iconText: 'SU', path: ROUTES.EVALUADOS,bases:['sideco'] },
        
      ]
       
  },
  { label: 'LMS', type: 'label',auth: authRoles.sa },
  

      { name: 'Lms', icon: 'schedule',auth: authRoles.sa , children: [
        { name: 'Inicio', iconText: 'SI', path: ROUTES.LMS_HOME },
        { name: 'Curso', iconText: 'SI', path: ROUTES.LMS_CURSO },
      
        { name: 'Curso detalle', iconText: 'SU', path:ROUTES.LMS_CURSO_DETALLE },
        
      ]},
      { name: diccionario.evaluaciones,icon: 'thumb_up',auth: authRoles.sa ,badge: { value: '30+', color: 'red' },   children: [
        { name:diccionario.crear, iconText: 'SI', path: ROUTES.ESTRUCTURA },
        { name: diccionario.modificar,iconText: 'SI', path:ROUTES.ESTRUCTURA_MODIFICAR },
        { name: diccionario.listados, iconText: 'SU', path: ROUTES.SIGN_UP },
        
      ]
       
  },
  { name: 'Informes',icon: 'trending_up',auth: authRoles.sa ,   children: [
    { name: diccionario.resumen, iconText: 'SI',auth: authRoles.sa , path: ROUTES.INFORMES },
    
    
  ]
   
},
 
  { label: 'PAGES', type: 'label',auth: authRoles.sa  },
  {
    name: 'Session/Auth',
    icon: 'security',auth: authRoles.sa ,
    children: [
      { name: 'Sign in', iconText: 'SI', path:ROUTES.SIGN_IN },
      { name: 'Sign up', iconText: 'SU', path: ROUTES.SIGN_UP },
       { name: 'Forgot Password', iconText: 'FP', path: '/session/forgot-password' },
      { name: 'Error', iconText: '404', path: '/session/404' },
    ],
  },
  { label: 'Components', type: 'label',auth: authRoles.sa },
  {
    name: 'Components',
    icon: 'favorite',auth: authRoles.sa,
    badge: { value: '30+', color: 'secondary' },
    children: [
      { name: 'Auto Complete', path: '/material/autocomplete', iconText: 'A' },
      { name: 'Buttons', path: '/material/buttons', iconText: 'B' },
      { name: 'Checkbox', path: '/material/checkbox', iconText: 'C' },
      { name: 'Dialog', path: '/material/dialog', iconText: 'D' },
      { name: 'Expansion Panel', path: '/material/expansion-panel', iconText: 'E' },
      { name: 'Form', path: '/material/form', iconText: 'F' },
      { name: 'Icons', path: '/material/icons', iconText: 'I' },
      { name: 'Menu', path: '/material/menu', iconText: 'M' },
      { name: 'Progress', path: '/material/progress', iconText: 'P' },
      { name: 'Radio', path: '/material/radio', iconText: 'R' },
      { name: 'Switch', path: '/material/switch', iconText: 'S' },
      { name: 'Slider', path: '/material/slider', iconText: 'S' },
      { name: 'Snackbar', path: '/material/snackbar', iconText: 'S' },
      { name: 'Table', path: '/material/table', iconText: 'T' },
    ],
  },
  {
    name: 'Charts',
    icon: 'trending_up',auth: authRoles.sa ,
    children: [{ name: 'Echarts', path: '/charts/echarts', iconText: 'E' }],
  },
  {
    name: 'Documentation',
    icon: 'launch',
    type: 'extLink',
    path: 'http://www.punk-lab.com',
    por:'per'
  },

])}