import '../fake-db';
import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { MatxTheme } from './components';
import { AuthProvider } from './contexts/JWTAuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { Store } from './redux/Store';
import routes from './routes';
import { withAuthentication } from './firebase/Session';
import { persistStore } from 'redux-persist'
import { PersistGate} from 'redux-persist/integration/react'

const App = () => {
  const content = useRoutes(routes);
const persistor= persistStore(Store)



  return (
    <PersistGate   persistor={persistor}>
    <Provider store={Store}>
      <SettingsProvider>
        <MatxTheme>
          <AuthProvider>{content}</AuthProvider>
        </MatxTheme>
      </SettingsProvider>
    </Provider>
    </PersistGate>
  );
};

export default withAuthentication(App);


