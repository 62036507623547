import {lenguaje} from '../../../lenguajes/index'

import { createSlice } from '@reduxjs/toolkit';

const initialState =
{
    diccionario:lenguaje.ESP.diccionario,
    lenguaje:lenguaje.ESP.lenguaje,
    nombreBase:''

};




export const LenguajeReducerSlice = createSlice({
  name: 'LenguajeReducerSlice',
  initialState:{...initialState},
  reducers: {
    cambiarBase: (state, action) => {

      state.nombreBase= action.payload
     
  
   
    },
    cambiarIdioma: (state, action) => {

      state.diccionario= action.payload.diccionario
      state.lenguaje= action.payload.lenguaje
  
   
    }
 

  }
});


// Action creators are generated for each case reducer function
export const {cambiarIdioma,cambiarBase} = LenguajeReducerSlice.actions

export default LenguajeReducerSlice.reducer


