export const EMPLEADOS = '/listausuarios';
export const EMPLEADO = '/empleado';
export const EMPLEADO_MODIFICAR = '/empleadomodificar';


export const PUESTOS = '/listapuestos';
export const PUESTO = '/puesto';
export const PUESTO_MODIFICAR = '/puestomodificar';

export const ESTRUCTURA = '/estructura';
export const ESTRUCTURA_MODIFICAR = '/estructuramodificar';
export const INFO_ESTRUCTURA='/info_estructura'; 

export const ESTRUCTURAS='/listaestructuras'; 

export const PRUEBAS = '/listadopruebas';
export const PRUEBA = '/prueba';
export const PRUEBA_MODIFICAR = '/pruebamodificar';

export const  PRUEBA_FORMULARIO = '/pruebaformulario';


export const EVALUACIONES = '/listadoevaluaciones';
export const EVALUACION = '/evaluacion';
export const EVALUACION_MODIFICAR = '/evaluacionmodificar';
export const EVALUADOS = '/listadoevaluados';

export const INFORMES = '/informes';


export const SIGN_UP = '/session/signup';

export const SIGN_IN = '/session/signin';



export const FORGOT ='/session/forgot-password';
export const NOTFOUND = '/session/404';

export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const INCI = '/inci';
export const MASTER = '/datosmaestros';
export const PASSWORD_FORGET = '/pw-forget';
export const ACTIVIDADES = '/actividades';
export const COMUNIDADES = '/comunidades';
export const GREMIOS = '/gremios';
export const DOCUMENTOS = '/documentos';
export const USUARIOS = '/usuarios';
export const ROLES = '/roles';
export const ZONAS = '/zonascomunes';
export const VOTACION = '/votaciones';
export const JUNTA = '/junta';
export const JUNTASVIRTUALES = '/virtuales';
export const CATEGORIAS = '/categorias';
export const PRIVACIDAD = '/privacidad';
export const AVISO_LEGAL = '/aviso_legal';
export const POLITICA_COOKIES = '/politica_cookies';
export const POLITICA_PRIVACIDAD = '/politica_privacidad';
export const LOPD = '/lopd';
export const SUPERUSER = '/superuser';
export const FACTURACION ='/facturacion'
export const PORTA = '/portalpruebammm';

export const INCI_GREMIOS = '/inci_gremios/:id'

export const CONTACTO= '/contact/:contactId'

export const LISTA_CONTACTO= '/contact-list'


export const FORM_CONTACTO= '/contact-form'
export const ACUERDO= '/deal/:dealId'
export const LISTA_ACUERDO= '/deal-list'
export const FORM_ACUERDO= '/deal-form' 

export const LISTA_COMUNIDADES= '/listacomunidades'
export const COMUNIDAD= '/comunidad'


export const LMS_HOME= '/lms'
export const LMS_CURSO='/lms/curso'
export const LMS_CURSO_DETALLE='/lms/curso-detalle'