import React from 'react';

import { compose } from 'recompose';
import { Navigate } from "react-router-dom";
import { withFirebase } from '../index';
import * as ROUTES from '../../constants/routes';
import AuthUserContext from './context';
import { getAuth} from "firebase/auth";
import { useNavigate } from 'react-router-dom';


const withAuthorization = condition => Component => {
 // const navigate = useNavigate();
 
const WithAuthorization = () => {
  //const [autorizado, setAutorizado] = useState(false);
  const navigate = useNavigate();
  const auth =getAuth()
 
  auth.onAuthStateChanged(
        authUser => {

          if (!condition(authUser)) {
              navigate(ROUTES.SIGN_IN)
          }
      
        },
      );
    

      return (
        <AuthUserContext.Consumer>
        {authUser =>
          condition(authUser) ? <Component/> : null
        }
      </AuthUserContext.Consumer>
      );
    
  }
  return compose(
    withFirebase,
  )(WithAuthorization);
};
export default withAuthorization;
